import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Button, Col, Image, Row, Spinner } from 'react-bootstrap';

import 'suneditor/dist/css/suneditor.min.css';
import { MultiSelect } from 'react-multi-select-component';
import Select from 'react-dropdown-select';

import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { FileUploader } from 'react-drag-drop-files';
import { RefetchFunction } from 'axios-hooks';

import './styles/styles.css';
import {
  LockResponse,
  Property,
  Room,
  RoomResponse,
  SelectType,
  TPRoom,
} from '../../../modules/interfaces';
import { useAuth, useNotifications, useRequest } from '../../../modules/hooks';
import { endpoints } from '../../../modules/mappers/urls';
import { FileStore } from '../PostEditor';

import { PreviewItem } from '../../Customize/PreviewItem';
import { getApiErrorMessage } from '../../../modules/utils/transform';

interface RoomEditorModalProps {
  data?: RoomResponse;
  show: boolean;
  duplicate?: boolean;
  handleClose: () => void;
  refetchRooms: RefetchFunction<unknown, unknown>;
}

interface Option {
  value: any;
  label: string;
  key?: string;
  disabled?: boolean;
  amenities?: Option[];
}

export const RoomEditorModal: FC<RoomEditorModalProps> = ({
  data,
  show,
  duplicate,
  handleClose,
  refetchRooms,
}) => {
  const { setSimpleToasts } = useNotifications()!;
  const [amenities, setAmenities] = useState<Option[]>([]);
  const [selectedProperties, setSelectedProperties] = useState<Option[]>([]);
  const [selectedRoomId, setSelectedRoomId] = useState<Option[]>([]);
  const [selectedLockId, setSelectedLockId] = useState<Option[]>([]);
  const [amenitiesOption, setAmenitiesOption] = useState<Option[]>([]);
  const [propertiesList, setPropertiesList] = useState<Property[]>([]);
  const [TpRooms, setTpRooms] = useState<TPRoom[]>([]);
  const [roomLocks, setRoomLocks] = useState<LockResponse[]>([]);
  const [files, setFiles] = useState<FileStore[]>([]);
  const [fileLs, setFileList] = useState<FileList | null>(null);
  const [mediaDeleted, setMediaDeleted] = useState<string[]>([]);

  const fileTypes = useMemo(() => ['JPEG', 'JPG', 'PNG', 'GIF'], []);
  const { credentialsInfo } = useAuth()!;

  const userPropertiesUrl = `${endpoints.PROPERTIES}`;
  const [{ data: propertiesData, loading: userPropertiesLoading }] = useRequest<
    Property[]
  >(userPropertiesUrl, 'get', {
    authToken: credentialsInfo?.token,
  });

  const roomsURL = `${endpoints.ROOMS}/third-party/mews`;
  const [{ data: roomMewsData, loading: roomMewsLoading }, refetch] = useRequest<
    TPRoom[]
  >(
    roomsURL,
    'get',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );

  const [{ data: locksResult, loading: loadingLocks, error: lockError }, getLocks] =
    useRequest<LockResponse[]>(endpoints.LOCKS, 'get', {
      authToken: credentialsInfo?.token,
    });

  useEffect(() => {
    if (data && !duplicate) {
      refetch({ params: { updateId: data?.externalId || '' } });
    } else {
      refetch();
    }
  }, [show]);

  const {
    control,
    register,
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  const [{ data: insertResult, error: insertRoomError }, insertRoom] = useRequest<string>(
    endpoints.ROOMS,
    'post',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );

  const updateRoomUrl = `${endpoints.ROOMS}/${data?.externalId}`;
  const [{ data: updateResult, error: updateRoomError }, updateRoom] = useRequest<string>(
    updateRoomUrl,
    'patch',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );

  const createRoom = (roomData: Room) => {
    const formData = new FormData();

    formData.append('name', roomData.name || '');
    formData.append('abbreviation', roomData.abbreviation || '');
    formData.append('maxOccupants', roomData.maxOccupants || '');
    formData.append('roomSize', roomData.roomSize);
    formData.append('propertyId', roomData?.properties || '');
    formData.append('checkInSteps', roomData?.checkInSteps || '');
    formData.append('wifiName', roomData?.wifiName || '');
    formData.append('wifiPassword', roomData?.wifiPassword || '');
    formData.append('description', roomData?.description || '');
    formData.append('bedType', roomData?.bedType || '');
    formData.append('roomId', roomData?.roomId || '');

    if (amenities) {
      const values = amenities.map(item => item.value);
      const commaSeparatedValues = values.join(',');

      formData.append('amenities', commaSeparatedValues);
    }

    if (files) {
      const media = files.filter(file => file.file.name !== '').map(file => file.file);
      media.forEach(file => {
        const fileName = file.name;
        formData.append('files', file, fileName);
      });
    }

    if (mediaDeleted.length > 0) {
      formData.append('mediaDeleted', JSON.stringify(mediaDeleted));
    }

    if (selectedLockId) {
      const [value] = selectedLockId;
      const lockParams = { lockName: value.label, lockId: value.value };
      formData.append('lockParams', JSON.stringify(lockParams));
    }

    if (data && !duplicate) {
      updateRoom({
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: credentialsInfo?.token,
        },
      });
    } else {
      insertRoom({
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: credentialsInfo?.token,
        },
      });
    }
  };

  const onSubmit = async (roomData: any) => {
    const validated = await trigger([
      'name',
      'abbreviation',
      'maxOccupants',
      'roomSize',
      'properties',
      'roomId',
    ]);

    if (validated) {
      createRoom(roomData);
    }
  };

  const resetForm = () => {
    reset({
      name: '',
      abbreviation: '',
      maxOccupants: '',
      roomSize: '',
      checkInSteps: '',
      wifiName: '',
      wifiPassword: '',
      description: '',
      bedType: '',
      roomId: '',
      properties: '',
      roomLockId: '',
    });
    setSelectedProperties([]);
    setSelectedRoomId([]);
    setAmenities([]);
    setFiles([]);
    setSelectedLockId([]);
  };

  const getRoomDropDownData = () => {
    const dropDownList: SelectType[] = [];

    TpRooms.forEach(item => {
      dropDownList.push({
        value: item.Id,
        label: `Room Number ${item.Name}`,
      });
    });

    return dropDownList;
  };

  const handleFileChange = useCallback(
    (fileList: FileList) => {
      Array.from(fileList).forEach(file => {
        const fileAdded = files.some(item => item.file.name === file.name);

        if (!fileAdded) {
          const fileUrl = URL.createObjectURL(file);
          setFiles([...files, { id: undefined, url: fileUrl, file }]);
        }
      });
    },
    [files],
  );

  const handleDeleteFile = useCallback(
    (fileUrl: string, uuid: string | undefined) => {
      const filteredFiles = files.filter(item => item.url !== fileUrl);
      const list = new DataTransfer();

      URL.revokeObjectURL(fileUrl);

      filteredFiles.forEach(fileStore => list.items.add(fileStore.file));

      const { files: dtFiles } = list;

      if (uuid) {
        setMediaDeleted([...mediaDeleted, uuid]);
      }

      setFileList(dtFiles.length ? dtFiles : null);
      setFiles(filteredFiles);
    },
    [files, mediaDeleted],
  );

  const onClose = () => {
    handleClose();
    resetForm();
  };

  const getModalTitle = useCallback(() => {
    if (duplicate) {
      return 'Duplicate this Room';
    }
    if (data) {
      return 'Edit this Room';
    }
    return 'Add new Room';
  }, [duplicate, data]);

  useEffect(() => {
    if (!data) return;

    reset({
      name: data?.name || '',
      abbreviation: data?.abbreviation || '',
      maxOccupants: data?.maxOccupants || '',
      roomSize: data?.roomSize || '',
      checkInSteps: data?.checkInSteps || '',
      wifiName: data?.wifiName || '',
      wifiPassword: data?.wifiPassword || '',
      description: data?.description || '',
      bedType: data?.bedType || '',
      roomId: data?.roomId || '',
      roomLockId: data?.lockId || '',
      properties: data?.property?.uuid || '',
    });

    if (data?.media && !duplicate) {
      const mediaData = data?.media.map((item): FileStore => {
        return { id: item.uuid, url: item.uri, file: new File([], '') };
      });
      setFiles(mediaData);
    }

    setSelectedProperties([
      { label: data.property?.name || '', value: data.property?.uuid || '' },
    ]);

    if (data?.property?.propertyAmenities) {
      const amenitiesOptions = data.property.propertyAmenities.map(amenity => ({
        value: amenity.name,
        label: amenity.name,
      }));
      setAmenitiesOption(amenitiesOptions);
    }

    const amenitiesList: Option[] | undefined = data.amenities
      ?.split(',')
      .map(item => ({ label: item, value: item }));

    if (amenitiesList) {
      setAmenities(amenitiesList);
    }
  }, [data]);

  useEffect(() => {
    if (!TpRooms || !data || duplicate) return;

    const value: TPRoom | undefined = TpRooms?.find(
      item => item?.Id === data?.externalId,
    );

    setSelectedRoomId([{ value: value?.Id, label: `Room Number ${value?.Name}` }]);
  }, [TpRooms, refetch]);

  useEffect(() => {
    if (!propertiesData) return;

    setPropertiesList(propertiesData.result);
  }, [propertiesData]);

  useEffect(() => {
    if (lockError) {
      getLocks();
    }

    if (!locksResult) return;
    setRoomLocks(locksResult.result);

    if (!data || !data.lockId) return;

    const roomLock = locksResult.result.find(item => item.id === data.lockId);

    if (roomLock) {
      setSelectedLockId([{ value: roomLock.id, label: roomLock.customerReference }]);
    }
  }, [locksResult, lockError, data]);

  useEffect(() => {
    if (!roomMewsData) return;

    setTpRooms(roomMewsData?.result);
  }, [roomMewsData]);

  useEffect(() => {
    if (insertResult || updateResult) {
      onClose();
      refetchRooms();
    }
    if (insertRoomError) {
      const message = getApiErrorMessage(insertRoomError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
    if (updateRoomError) {
      const message = getApiErrorMessage(updateRoomError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
  }, [insertResult, updateResult, insertRoomError, updateRoomError]);

  const getPropertyDropDownData = () => {
    const dropDownList: SelectType[] = [];

    propertiesList.forEach(item => {
      let amenitiesOptions: Option[] = [];
      if (item?.propertyAmenities) {
        amenitiesOptions = item.propertyAmenities.map(amenity => ({
          value: amenity.name,
          label: amenity.name,
        }));
      }
      dropDownList.push({
        value: item.uuid,
        label: item.name,
        amenities: amenitiesOptions,
      });
    });
    return dropDownList;
  };

  const getLockDropDownData = () => {
    const dropDownList: SelectType[] = [];

    roomLocks.forEach(item => {
      dropDownList.push({
        value: item.id,
        label: item.customerReference,
      });
    });
    return dropDownList;
  };

  const selectProperties = (values: Option[]) => {
    setSelectedProperties(values);
    setAmenitiesOption(values[0]?.amenities || []);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="xl"
      dialogClassName="post-editor-modal"
      backdrop="static"
    >
      {loadingLocks || roomMewsLoading || userPropertiesLoading ? (
        <Row style={{ marginTop: '50px', marginBottom: '50px' }}>
          <Spinner
            animation="border"
            variant="primary"
            className="d-block m-auto"
            style={{ color: '#2BD579' }}
          />
        </Row>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header>
            <Modal.Title className="editor-title">Room</Modal.Title>
            <span className="editor-sub-title">{getModalTitle()}</span>
          </Modal.Header>
          <Modal.Body>
            <Form>
              {TpRooms.length === 0 && (
                <span className="error-text">
                  There are no more unlinked rooms available with the third-party
                  provider. Unable to link a room at this time.
                </span>
              )}
              {propertiesList.length !== 0 && (
                <Row className="mt-24px">
                  <Form.Group className="border-bottom">
                    <Form.Label className="editor-title-label">Property</Form.Label>
                    <Controller
                      name="properties"
                      control={control}
                      rules={{ required: 'This is required.' }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={getPropertyDropDownData()}
                          values={selectedProperties}
                          onChange={values => {
                            field.onChange(values[0]?.value || '');
                            selectProperties(values);
                          }}
                          loading={userPropertiesLoading}
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="properties"
                      render={({ message }) => (
                        <span className="error-text">{message}</span>
                      )}
                    />
                  </Form.Group>
                </Row>
              )}
              {TpRooms.length !== 0 && (
                <Row className="mt-24px">
                  <Form.Group className="border-bottom">
                    <Form.Label className="editor-title-label">Select Room</Form.Label>
                    <Controller
                      name="roomId"
                      control={control}
                      rules={{ required: 'This is required.' }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={getRoomDropDownData()}
                          values={selectedRoomId}
                          onChange={values => {
                            field.onChange(values[0]?.value || '');
                            setSelectedRoomId(values);
                          }}
                          loading={roomMewsLoading}
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="roomId"
                      render={({ message }) => (
                        <span className="error-text">{message}</span>
                      )}
                    />
                  </Form.Group>
                </Row>
              )}
              {roomLocks.length !== 0 && (
                <Row className="mt-24px">
                  <Form.Group className="border-bottom">
                    <Form.Label className="editor-title-label">
                      Select Room Lock
                    </Form.Label>
                    <Controller
                      name="roomLockId"
                      control={control}
                      rules={{ required: 'This is required.' }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={getLockDropDownData()}
                          values={selectedLockId}
                          onChange={values => {
                            field.onChange(values[0]?.value || '');
                            setSelectedLockId(values);
                          }}
                          loading={loadingLocks}
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="roomLockId"
                      render={({ message }) => (
                        <span className="error-text">{message}</span>
                      )}
                    />
                  </Form.Group>
                </Row>
              )}
              <Row className="mt-24px">
                <Col md={6}>
                  <Form.Label className="editor-title-label">Room Name</Form.Label>
                  <Form.Control
                    className="editor-control"
                    placeholder="Enter room name..."
                    {...register('name', { required: 'This is required.' })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="name"
                    render={({ message }) => (
                      <span className="error-text">{message}</span>
                    )}
                  />
                </Col>
                <Col md={6}>
                  <Form.Label className="editor-title-label">
                    Room Abbreviation
                  </Form.Label>
                  <Form.Control
                    className="editor-control"
                    placeholder="Enter room abbreviation"
                    {...register('abbreviation', { required: 'This is required.' })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="abbreviation"
                    render={({ message }) => (
                      <span className="error-text">{message}</span>
                    )}
                  />
                </Col>
              </Row>
              <Row className="mt-24px">
                <Col md={6}>
                  <Form.Label className="editor-title-label">Max Occupants</Form.Label>
                  <Form.Control
                    type="number"
                    className="editor-control"
                    placeholder="Enter room max occupants..."
                    {...register('maxOccupants', {
                      required: 'This is required.',
                      valueAsNumber: true,
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="maxOccupants"
                    render={({ message }) => (
                      <span className="error-text">{message}</span>
                    )}
                  />
                </Col>

                <Col md={6}>
                  <Form.Label className="editor-title-label">Room Size</Form.Label>
                  <Form.Control
                    type="number"
                    className="editor-control"
                    placeholder="Enter room size..."
                    {...register('roomSize', {
                      required: 'This is required.',
                      valueAsNumber: true,
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="roomSize"
                    render={({ message }) => (
                      <span className="error-text">{message}</span>
                    )}
                  />
                </Col>

                <Row className="mt-24px">
                  <Col md={6}>
                    <Form.Label className="editor-title-label">Wifi Name</Form.Label>
                    <Form.Control
                      className="editor-control"
                      placeholder="Enter wifi name..."
                      {...register('wifiName')}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="wifiName"
                      render={({ message }) => (
                        <span className="error-text">{message}</span>
                      )}
                    />
                  </Col>
                  <Col md={6}>
                    <Form.Label className="editor-title-label">Wifi Password</Form.Label>
                    <Form.Control
                      className="editor-control"
                      placeholder="Enter wifi password..."
                      {...register('wifiPassword')}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="wifiPassword"
                      render={({ message }) => (
                        <span className="error-text">{message}</span>
                      )}
                    />
                  </Col>
                </Row>
              </Row>
              <Row className="mt-24px">
                <Col md={6}>
                  <Form.Label className="editor-title-label">Room Description</Form.Label>
                  <Form.Control as="textarea" rows={5} {...register('description')} />
                </Col>
                <Col md={6}>
                  <Form.Label className="editor-title-label">Check in steps</Form.Label>
                  <Form.Control as="textarea" rows={5} {...register('checkInSteps')} />
                </Col>
              </Row>

              <Row className="mt-24px check-box-group">
                <Col md={6}>
                  <Form.Label className="editor-title-label">Bed Type</Form.Label>
                  <Form.Control
                    className="editor-control"
                    placeholder="Enter the bed type..."
                    {...register('bedType', { required: true })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="bedType"
                    render={({ message }) => (
                      <span className="error-text">{message}</span>
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Form.Group className="mt-24px pb-24px border-bottom">
                  <Form.Label className="editor-title-label">Amenities</Form.Label>

                  {amenitiesOption.length === 0 ? (
                    <Form.Control
                      className="editor-control"
                      placeholder="Select..."
                      disabled
                    />
                  ) : (
                    <MultiSelect
                      options={amenitiesOption}
                      value={amenities}
                      labelledBy="Default"
                      onChange={setAmenities}
                    />
                  )}
                </Form.Group>
              </Row>

              <Row className="mt-24px">
                <Form.Group className="mt-24">
                  <Form.Label className="editor-title-label">Media Files</Form.Label>
                  <Row className="m-0 upload-section">
                    <Col sm={12} className="preview-section">
                      <Row>
                        {files.map(item => {
                          return (
                            <PreviewItem
                              url={item.url}
                              key={item.url}
                              fileId={item.url}
                              uuid={item.id}
                              onDelete={handleDeleteFile}
                            />
                          );
                        })}
                      </Row>
                    </Col>
                    <Col sm={12} className="file-upload text-center">
                      <Image className="w-auto" alt="" src="/file-upload.svg" />
                      <div className="text-center react-file-uploader">
                        <span className="d-block">
                          <span className="upload-letter">
                            Upload a file or drag and drop
                          </span>
                        </span>
                        <span className="d-block">
                          <span className="upload-formats">
                            {fileTypes.join(',')} to 50MB
                          </span>
                        </span>
                        <FileUploader
                          handleChange={handleFileChange}
                          name="file"
                          multiple
                          types={fileTypes}
                          className="file-uploader"
                          fileOrFiles={fileLs}
                        />
                      </div>
                    </Col>
                  </Row>
                </Form.Group>
              </Row>
            </Form>
          </Modal.Body>

          <Modal.Footer className="mt-48px">
            <Button className="cancel-button" onClick={onClose}>
              Cancel
            </Button>
            <Button className="save-button" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </form>
      )}
    </Modal>
  );
};
