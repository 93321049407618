export enum PaymentStatus {
  PAID = 'PAID',
  PENDING = 'PENDING',
  ERROR = 'ERROR',
}

export enum ArticleStatus {
  PUBLISHED = 'PUBLISHED',
  DRAFT = 'DRAFT',
  ARCHIVED = 'ARCHIVED',
}

export enum UserStatus {
  SUSPENDED = 'SUSPENDED',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum AuthStatus {
  pending,
  signedIn,
  signedOut,
}
