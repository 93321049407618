export const routes = {
  BALANCES: '/balances',
  HOME: '/',
  LOGIN: '/login',
  FORGOTPASSWORD: '/forgotpassword',
  CREATENEWPASSWORD: '/createnewpassword',
  RESERVATIONS: '/reservations',
  INBOX: '/inbox',
  RESERVATION_DETAILS: '/reservations/:reservationId/details',
  ARTICLES: '/articles',
  CATEGORIES: '/categories',
  REPORTS: '/reports',
  REVENUEREPORTS: '/revenuereports',
  ROOMREVENUEREPORTS: '/roomrevenuereports',
  ARTICLES_DETAILS: '/articles-details',
  ARTICLES_SEARCH: '/articles-search',
  ARTICLES_ADMIN: '/articles-admin',
  ACCEPT_INVITE: '/accept-invite',
  MORE: '/more',
  USERS: '/users',
  USERS_MANAGEMENT: '/user-management',
  ROLE_MANAGEMENT: '/role-management',
  ROOMS: '/rooms',
  PROPERTIES: '/properties',
  RESERVATION: '/reservation',
  GUEST_PORTAL: '/guest-portal/:reservationId',
  GUEST_REGISTRATION: '/guest-registration/:reservationId',
  FEEDBACK: '/feedback/:reservationId',
  PACKAGES: '/packages',
  PACKAGES_MANAGEMENT: '/packages-management',
};

export const endpoints = {
  BALANCES: 'v1/balances',
  DUE_BALANCES: 'v1/balances/due-payment',
  CREATE_PAYMENT_INTENT: 'v1/reservations/create-payment',
  MEWS_RESERVATIONS: 'v2/reservations',
  RESERVATION: 'v2/reservations',
  TOKEN_AUTH: 'v1/auth/token',
  CATEGORIES: 'v1/categories',
  PROPERTIES: 'v1/properties',
  MESSAGING: 'v1/messaging',
  ROOMS: 'v1/rooms',
  USERS: 'v1/users',
  ARTICLES: 'v1/articles',
  ARTICLES_STATUS: 'v1/articles/status',
  ROLES: 'v1/roles',
  PAYMENTS: 'v1/payments/reservation-payments',
  PAYMENT_METHODS: 'v1/payments/payment-methods',
  CREATE_REFUND: 'v1/payments/refund-charge',
  PAYOUT_INFORMATION: 'v1/reservations/payout-information',
  PACKAGES: 'v1/packages',
  GUESTS: 'v1/guests',
  GUEST_RESERVATIONS: 'v1/guest-reservations',
  LOCKS: 'v1/locks',
  RATING: 'v1/reservation-ratings',
  RESERVATION_PACKAGES: 'v1/packages/reservation-packages',
  PREVIOUS_RESERVATIONS: 'v1/guest-reservations/previous-reservations',
  CONFIG: 'v1/config',
};
